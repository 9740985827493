import { useRef } from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay/legacy';
import { useDispatch } from 'react-redux';
import { VisitorTracking } from 'dibs-visit-tracking/exports/visitorTracking';
import { getBuyerId, getGuestId } from 'dibs-cookie-jar';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';

import { sbMobileRefineMenuFiltersMap } from '../SbMobileRefineMenu/SbMobileRefineMenuFilters/sbMobileRefineMenuFiltersMap';
import { hasPersonalizedFilterQueryParam } from '../../shared/helpers/personalizedFiltersHelpers';
import { handleSbUserCountryCode } from '../helpers/handleSbUserCountryCode';
import { handlePageRefetchVariableOnUserChange } from '../../shared/handlePageRefetchVariableOnUserChange';

/* Actions */
import { showAuthModal } from '../../actions/sharedUserStorageActions';

/* Components */
import { MobileLayoutContainer } from '../../global/MobileLayoutContainer/MobileLayoutContainer';
import { SbSharedTrackingContainer } from '../SbSharedTrackingContainer/SbSharedTrackingContainer';
import { SbSharedHead } from '../SbSharedHead/SbSharedHead';
import { SbSharedRefetchContainer } from '../SbSharedRefetchContainer/SbSharedRefetchContainer';
import { SbMobileComponents } from '../SbMobileComponents/SbMobileComponents';

/* Hooks */
import { useSearchCorrections } from '../useSearchCorrections';
import { useDwelledOnPage } from 'dibs-buyer-layout/exports/dwelledOnPage';

const SbMobileLayoutComponent = ({ viewer, reinitTracking, relay }) => {
    const { itemSearch, user = null, regionalInfo = null, shippingFilters = null } = viewer;

    const relayVars = useRef({});
    const [searchCorrectionTerm] = useSearchCorrections({ itemSearch });
    const dispatch = useDispatch();

    useDwelledOnPage(PAGE_TYPE.SEARCH);

    function doRefetch(variables, renderVariables, callback) {
        relayVars.current = {
            ...relayVars.current,
            ...variables,
            userCountryCode: handleSbUserCountryCode({
                userCountryCode: variables?.userCountryCode,
                fetchSb: variables?.fetchSb,
            }),
        };
        relay.refetch(
            relayVars.current,
            { ...relayVars.current, ...renderVariables, fetchSb: true },
            callback
        );
    }

    function handleUserRefetch(variables, ...args) {
        const { isClient, fetchSbOnUserChange } = variables || {};
        const hasPersonalizedFilter = isClient && hasPersonalizedFilterQueryParam();

        //Personal-rerank needs to refetch s&b after login
        const fetchSb = handlePageRefetchVariableOnUserChange(variables) || fetchSbOnUserChange;
        doRefetch(
            {
                ...variables,
                fetchSb,
                skipUser: false,
                hasPersonalizedFilter,
            },
            ...args
        );
    }

    function handleSbRefetch(variables, renderVariables, callback) {
        /*
         * UserData is required for sponsored listings, add it to relay vars for refetch,
         * cause initial page could be not eligible for sponsored items, but subsequent page could (eg. by removing filters).
         * graphql will decide if sponsored items should be added in the end.
         */
        const userData = {
            personalizationId: getBuyerId(document.cookie) || '',
            guestId: getGuestId(document.cookie) || '',
            includeSponsoredItems: true,
        };

        doRefetch({ ...variables, ...userData, fetchSb: true }, renderVariables, () => {
            callback();

            new VisitorTracking();
            dispatch(showAuthModal(relay.environment));
        });
    }

    return (
        <MobileLayoutContainer
            user={user}
            onUserRefetch={handleUserRefetch}
            environment={relay.environment}
        >
            <SbSharedTrackingContainer
                viewer={viewer}
                itemSearch={itemSearch}
                user={user}
                reinitTracking={reinitTracking}
                refineMenuFiltersMap={sbMobileRefineMenuFiltersMap}
                searchCorrectionTerm={searchCorrectionTerm}
            />
            <SbSharedHead itemSearch={itemSearch} />
            <SbSharedRefetchContainer
                viewer={viewer}
                regionalInfo={regionalInfo}
                itemSearch={itemSearch}
                shippingFilters={shippingFilters}
                onSbRefetch={handleSbRefetch}
            />
            <SbMobileComponents
                viewer={viewer}
                itemSearch={itemSearch}
                user={user}
                isClient={!!relayVars.current.isClient}
                shippingFilters={shippingFilters}
            />
        </MobileLayoutContainer>
    );
};

SbMobileLayoutComponent.propTypes = {
    viewer: PropTypes.object,
    relay: PropTypes.object.isRequired,
    reinitTracking: PropTypes.func,
};

export const SbMobileLayout = createRefetchContainer(
    SbMobileLayoutComponent,
    {
        viewer: graphql`
            fragment SbMobileLayout_viewer on Viewer
            @argumentDefinitions(
                hasPersonalizedFilter: { type: "Boolean", defaultValue: false }
                userZipCode: { type: "String", defaultValue: "" }
                fetchRegionalInfo: { type: "Boolean", defaultValue: false }
            ) {
                ...SbMobileComponents_viewer
                ...SbSharedTrackingContainer_viewer
                itemSearch(
                    userId: $personalizationId
                    guestId: $guestId
                    uriRef: $uriRef
                    originalUrl: $originalUrl
                    first: $first
                    page: $page
                    localeOrigin: $localeOrigin
                    regions: $regions
                    disableForceFacet: $disableForceFacet
                    disableNonParameterizedUrlRedirects: $disableNonParameterizedUrlRedirects
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    includeSponsoredItems: $includeSponsoredItems
                    priceBookName: $priceBookName
                ) {
                    pageType
                    # Used by SearchCorrectionsProvider
                    ...useSearchCorrections_itemSearch
                    ...SbSharedRefetchContainer_itemSearch
                    ...SbSharedTrackingContainer_itemSearch
                    ...SbSharedHead_itemSearch
                    ...SbMobileComponents_itemSearch @include(if: $fetchSb)
                }
                user(userId: $userId) @include(if: $fetchUser) {
                    ...SbSharedTrackingContainer_user
                    ...SbMobileComponents_user
                }
                regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
                    @include(if: $fetchRegionalInfo) {
                    ...SbSharedRefetchContainer_regionalInfo
                }
                shippingFilters(
                    uriRef: $uriRef
                    zipCode: $userZipCode
                    countryCode: $userCountryCode
                ) @include(if: $hasPersonalizedFilter) {
                    ...SbSharedRefetchContainer_shippingFilters
                    ...SbMobileComponents_shippingFilters
                }
            }
        `,
    },
    graphql`
        query SbMobileLayoutRefetchQuery(
            $userId: String = ""
            $personalizationId: String = ""
            $guestId: String = ""
            $fetchUser: Boolean!
            $rerankUserId: String = ""
            $rerankGuestId: String = ""
            $followSearchPages: [String]
            $followSearchTypes: [types] = [SEARCH, USER_PREFERENCE]
            $uriRef: String
            $originalUrl: String
            $first: Int!
            $page: Int!
            $localeOrigin: String = ""
            $showSeller: Boolean!
            $includeSponsoredItems: Boolean = false
            $isTrade: Boolean!
            $fetchSb: Boolean!
            $fetchTileVideo: Boolean = false
            $skipUser: Boolean = true
            $contentModuleId: String = ""
            $regions: String = ""
            $hasPersonalizedFilter: Boolean!
            $userZipCode: String = ""
            $userCountryCode: String = ""
            $disableForceFacet: Boolean = true
            $fetchRegionalInfo: Boolean = false
            $isClient: Boolean!
            $disableNonParameterizedUrlRedirects: Boolean = false # Only used for static SB
            $priceBookName: String
            $regionsList: [String]
            $pageDisplayEnum: PageDisplayEnum = searchAndBrowse
        ) {
            viewer {
                ...SbSharedTrackingContainer_viewer
                ...SbMobileComponents_viewer
                itemSearch(
                    uriRef: $uriRef
                    originalUrl: $originalUrl
                    first: $first
                    page: $page
                    localeOrigin: $localeOrigin
                    regions: $regions
                    disableForceFacet: $disableForceFacet
                    disableNonParameterizedUrlRedirects: $disableNonParameterizedUrlRedirects
                    userCountryCode: $userCountryCode
                    regionsList: $regionsList
                    priceBookName: $priceBookName
                    userId: $personalizationId
                    guestId: $guestId
                    includeSponsoredItems: $includeSponsoredItems
                ) @include(if: $fetchSb) {
                    pageType
                    ...SbSharedRefetchContainer_itemSearch
                    ...SbSharedTrackingContainer_itemSearch
                    ...SbSharedHead_itemSearch
                    ...SbMobileComponents_itemSearch
                }
                user(userId: $userId) @include(if: $fetchUser) @skip(if: $skipUser) {
                    ...SbMobileComponents_user
                    ...SbSharedTrackingContainer_user
                    ...SbMobileComponents_user
                }
                regionalInfo(zipCode: $userZipCode, countryCode: $userCountryCode)
                    @include(if: $fetchRegionalInfo) {
                    ...SbSharedRefetchContainer_regionalInfo
                }
                shippingFilters(
                    uriRef: $uriRef
                    zipCode: $userZipCode
                    countryCode: $userCountryCode
                ) @include(if: $hasPersonalizedFilter) {
                    ...SbSharedRefetchContainer_shippingFilters
                    ...SbMobileComponents_shippingFilters
                }
            }
        }
    `
);
